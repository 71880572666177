<template>
  <div class="aiz-user-panel">
    
    <div class="card">
      <div class="card-header row gutters-5">
        <div class="text-center text-md-left">
          <h5 class="mb-md-0 h5">{{ $t('xian-xia-zhi-fu') }} #10010239</h5>
          <div class="mt-2">
            <span>{{ $t('zhang-san') }}</span>
            <span class="ml-2">2023-08-01-14-31-33</span>
            <span class="badge badge-inline badge-secondary ml-2"> pending </span>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form >
          <div class="form-group">
            <textarea class="form-control mb-4" name="reply" rows="8" required></textarea>
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <div class="input-group" data-toggle="aizuploader" data-type="image" data-multiple="true">
                <div class="input-group-prepend">
                  <div class="input-group-text bg-soft-secondary font-weight-medium">{{ $t('liu-lan') }}</div>
                </div>
                <div class="form-control file-amount" @click="addFile(2)">{{form.filename ? form.filename : '选择文件'}}</div>
                <input type="file" class="hide-btn" id="fileInput" @change="uploadFile">
              </div>
              <fileListBar :fileList="fileList"></fileListBar>
            </div>
          </div>
          <div class="form-group mb-0 text-right">
            <button type="submit" class="btn btn-sm btn-primary" onclick="submit_reply('pending')">{{ $t('fa-song-hui-fu') }}</button>
          </div>
        </form>
        <div class="pad-top">
          <ul class="list-group list-group-flush">
            <li class="list-group-item px-0" v-for="(item, i) in messageList" :key="i">
              <div class="media">
                <a class="media-left" href="#">
                  <span class="avatar avatar-sm mr-3">
                      <img :src="item.headerUrl">
                  </span>
                </a>
                <div class="media-body">
                    <div class="comment-header">
                        <span class="text-bold h6 text-muted">{{item.username}}</span>
                        <p class="text-muted text-sm fs-11">{{item.date}}</p>
                    </div>
                </div>
              </div>
              <div>
                <div v-html="item.message"></div>
                <br>
                <div v-for="(file, index) in item.fileList" :key="index">
                  <a  download="" class="badge badge-lg badge-inline badge-light mb-1">
                    <i class="las la-download text-muted">{{ file.name }}</i>
                  </a>
                  <br>  
                </div>  
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import pageBar from '@/components/page'
import fileListBar from '@/components/fileList'
import {Popup} from 'vant'
export default {
  components: {
    pageBar,
    fileListBar,
    Popup
  },
  data() {
    return {
      form: {
        fileName: ''
      },
      fileList: [],
      showPopup: false,
      userMoney: 10,
      messageList: [],
    }
  },
  methods: {
    cancel() {
      this.showPopup = false
    },
    add() {
      this.showPopup = true
    },
    addFile(type) {
      this.fileType = type
      document.getElementById('fileInput').click()
    },
    uploadFile() {
      let file = document.getElementById('fileInput').files[0]
      console.log(111, file)
      this.fileList.push(file)
    }
  }
}
</script>